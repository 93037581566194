import { createContext, useState, useEffect } from "react";
import { getPriorities } from "../utils/contract.utils";

export const SubcontractContext = createContext({
    subContractors: [], setSubContractors: () => null,
    activeSubcontractor: null, setActiveSubcontractor: () => null,
    subcontractEditor: {}, setSubcontractEditor: () => null,
    newSubContractEditor: {}, setNewSubContractEditor: () => null,
    allPriorities: [], setAllPriorities: () => null
});

export const SubcontractProvider = ({children}) => {
    const [subContractors, setSubContractors] = useState([]);
    const [activeSubcontractor, setActiveSubcontractor] = useState(null);
    const [subcontractEditor, setSubcontractEditor] = useState({
        editorOpen: false,
        showSave: true
    });
    const [newSubContractEditor, setNewSubContractEditor] = useState({
        editorOpen: false,
        showSave: true
    });
    const [allPriorities, setAllPriorities] = useState([]);

    useEffect(() => {
        getPriorities().then((data) => {
            setAllPriorities(data);
        })
    }, []);

    const value = {
        subContractors, setSubContractors,
        activeSubcontractor, setActiveSubcontractor,
        subcontractEditor, setSubcontractEditor,
        newSubContractEditor, setNewSubContractEditor,
        allPriorities, setAllPriorities
    };

    return (
        <SubcontractContext.Provider value={value}>{children}</SubcontractContext.Provider>
    );
}