export const Colors = {
    white: '#FFFFFF',
    menuBlue: '#396fb0',
    dark: '#353235',
    logoBlue: '#2665ad',
    logoGreen: '#12ac80',
    bodyBackground: '#f3f6fb',
    shadow: '#c4c4c4',
    mainFont: '#081833',
    secondaryFont: '#8d8d8d',
    danger: '#dc3545',
    logoBlue30: 'rgba(54, 145, 249, 0.3)',
    success: '#198754',
    black: '#000000',
    cardHeader: '#e5e5e5',
    cardFooter: '#e5e5e5',
    warning: '#ffc107',
    doddBlue: '#396FB0',
    doddDarkBlue: 'rgb(16,14,161)',
    doddGreen: '#1DCC8D',
    doddLightBlue: '#0E0D98',
    doddMidBlue: '#090979',
    doddYellow: '#FFB643',
    charcoal: '#545454',
    pink: '#CA579B',
    lpink: '#E5849C',
    lblue: '#AFDAFF',
    burnt: '#AD5425',
    boldYellow: '#FEDA02',
    boldGreen: '#52D75F',
    boldCyan: '#01B0E7',
    boldBlue: '#4060FC',
    boldViolet: '#8A0AB7',
    boldPink: '#F93871',
    tableBorder: '#dee2e6',
    dilogeOpacity: 'rgba(0, 0, 0, .6)',
    disabled: '#DDDDDD',
    black30: 'rgba(0, 0, 0, 0.3)',
    black40: 'rgba(0, 0, 0, 0.4)',
    black50: 'rgba(0, 0, 0, 0.5)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black70: 'rgba(0, 0, 0, 0.7)',
    black80: 'rgba(0, 0, 0, 0.8)',
    black90: 'rgba(0, 0, 0, 0.9)',
    hoverHighlight: '#f1f1f1',
    lightBg: '#f8f9fc',
    yellowBg: '#fffaf3',
    excelGreen: '#1D6F42',
    inputLabel: '#fa6917',
    orange: '#faa217'
}

export const ButtonColors = {
    base: Colors.menuBlue,
    baseHover: '#1a477a',
    danger: '#dc3545',
    dangerHover: '#a92835',
    black30: 'rgba(0, 0, 0, 0.3)',
    black40: 'rgba(0, 0, 0, 0.4)',
    success: '#20ad6b',
    successHover: '#198754',
    cancel: '#6c757d',
    cancelHover: '#5c636a'
}

export const Shadows = {
    dropShadow: `
        -webkit-box-shadow: -3px 2px 13px -7px rgba(0,0,0,0.75);
        -moz-box-shadow: -3px 2px 13px -7px rgba(0,0,0,0.75);
        box-shadow: -3px 2px 13px -7px rgba(0,0,0,0.75);
    `,
    insetShadow: `
        -webkit-box-shadow: inset 1px 1px 8px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: inset 1px 1px 8px 0px rgba(0,0,0,0.75);
        box-shadow: inset 1px 1px 8px 0px rgba(0,0,0,0.75);
    `,
    insetShadowPagination: `
        -webkit-box-shadow: inset 1px 1px 8px 0px rgb(0 0 0 / 75%);
        -moz-box-shadow: inset 1px 1px 8px 0px rgba(0,0,0,0.75);
        box-shadow: inset 0px -2px 1px 0px rgb(0 0 0 / 75%);
    }
    `,
    menuShadow: `
        box-shadow: 0px 1px 7px -3px rgba(0,0,0,0.75);
    `,
    menuShadowInset: `
        box-shadow: inset 0px 1px 7px -3px rgba(0,0,0,0.75);
    `
};

export const ScreenResolutions = {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    laptop: '1024px',
    laptopL: '1440px'
}

export const ScrollBar = `
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #8D8D8D #EDEDED;
}

/* Chrome, Edge and Safari */
&::-webkit-scrollbar {
    width: 10px;
    width: 10px;
}
&::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #EDEDED;
}

&::-webkit-scrollbar-track:hover {
    background-color: #EDEDED;
}

&::-webkit-scrollbar-track:active {
    background-color: #EDEDED;
}

&::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #8D8D8D;
}

&::-webkit-scrollbar-thumb:hover {
    background-color: #B0B0B0;
}

&::-webkit-scrollbar-thumb:active {
    background-color: #8D8D8D;
}
`

export const ContentHeight = `calc(100vh - 43px)`;