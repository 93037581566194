/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { getJob, getJobDocuments } from "../utils/job.utils";
import { ClientContext } from "./clients.context";
import { debounce } from "lodash";
import { searchClientJobs } from "../utils/job.utils";

export const JobContext = createContext({
    activeJob: Object, setActiveJob: () => null,
    loadActiveJob: String, setLoadActiveJob: () => null,
    jobDocuments: Array, setJobDocuments: () => null,
    filteredJobs: Array, setFilteredJobs: () => null,
    jobsLoading: Boolean, setJobsLoading: () => null,
    largeResults: Boolean, setLargeResults: () => null,
    jobSearchText: String, setJobSearchText: () => null
});

export const JobProvider = ({children}) => {
    const {activeClient} = useContext(ClientContext);
    const [activeJob, setActiveJob] = useState(null);
    const [loadActiveJob, setLoadActiveJob] = useState(null);
    const [jobDocuments, setJobDocuments] = useState(null);
    const [jobSearchText, setJobSearchText] = useState("");
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [jobsLoading, setJobsLoading] = useState(false);
    const [largeResults, setLargeResults] = useState(false);

    const runFilter = useMemo(() => debounce(async (search_text, client) => {
        if(search_text.length < 5) return setFilteredJobs([]);
        setJobsLoading(true);
        let filtered = await searchClientJobs(client, search_text);
        if(filtered.length > 50){ 
            filtered = filtered.splice(0, 50);
            setLargeResults(true);
        }
        setFilteredJobs(filtered);
        setJobsLoading(false);
    }, 700), []);

    useEffect(() => {
        setLargeResults(false);
        if(!jobSearchText.length) return setFilteredJobs([]);
        runFilter(jobSearchText, activeClient)
    }, [jobSearchText]);

    useEffect(() => {
        if(!loadActiveJob){
            setJobDocuments(null)
            return setActiveJob(null);
        }else{
            getJob(loadActiveJob).then(job => {
                setActiveJob(job);
                getJobDocuments(job.jobNo, job.subJobNo, activeClient.clientId, job.companyNumber).then(documents => {
                    const toDate = (dateString) => new Date(dateString);


                    let latestDocuments = new Map();
                    documents.forEach(document => {
                        const id = document.metadata.id;
                        const uploadDate = toDate(document.uploadDate);

                        if (!latestDocuments.has(id) || uploadDate > toDate(latestDocuments.get(id).uploadDate)) {
                            latestDocuments.set(id, document);
                        }
                    })

                    setJobDocuments(Array.from(latestDocuments.values()));
                })
                sessionStorage.setItem('lastJob', job.jobId);
            }).catch(error => {
                console.error(error);
            })
        }
    }, [loadActiveJob]);

    const value = {
        activeJob, setActiveJob,
        loadActiveJob, setLoadActiveJob,
        jobDocuments, setJobDocuments,
        jobSearchText, setJobSearchText,
        filteredJobs, setFilteredJobs,
        jobsLoading, setJobsLoading,
        largeResults, setLargeResults
    };
    
    return (
        <JobContext.Provider value={value}>{children}</JobContext.Provider>
    )
};