/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext } from "react";
import { ToastContext, TOAST_TYPES } from "./toast.context";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./user.context";
import { getClients, updateClient } from "../utils/client.utils";
import { getClientUsers } from "../utils/user.utils";

export const ClientContext = createContext({
    clients: Array, setClients: () => null,
    activeClient: null, setActiveClient: () => null,
    editClient: null, setEditClient: () => null,
    saveUpdatedClient: () => null,
    clientEditor: Object, setClientEditor: () => null,
    newClientEditor: Object, setNewClientEditor: () => null
});

export const ClientProvider = ({children}) => {
    const {currentUser} = useContext(UserContext);
    const {createToast} = useContext(ToastContext);
    const [clients, setClients] = useState([]);
    const [activeClient, setActiveClient] = useState(null);
    const [clientEditor, setClientEditor] = useState({
        editorOpen: false,
        showSave: true
    });
    const [newClientEditor, setNewClientEditor] = useState({
        editorOpen: false,
        showSave: true
    })
    const [editClient, setEditClient] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(!currentUser){
            setClients([]);
            setActiveClient(null);

        }else{
            const userClients = (currentUser.propertyCareSettings.isSiteAdmin || currentUser.permissions.portalPropertyCare === 3) ? ['*'] : currentUser.propertyCareSettings.clients;
            getClients(userClients).then(clientList => {
                setClients(clientList)
            }).catch(error => {
                console.error(error);
            })
        }
    }, [currentUser]);

    useEffect(() => {
        const lastClient = localStorage.getItem('client');
        if(!clients.length){
            return;
        }else{
            if(lastClient && clients.find(c => c.clientId === lastClient)) return setActiveClient(clients.find(c => c.clientId === lastClient));
            if(clients.length === 1) {
                setActiveClient(clients[0]);
                return navigate('/client');
            }
            navigate('/');
        }
    }, [clients]);

    useEffect(() => {
        if(!clientEditor.editorOpen) return;
        if(!activeClient) setClientEditor({editorOpen: false, showSave: true});
    }, [clientEditor]);

    useEffect(() => {
        if(!activeClient) return;
        setTimeout(() => {
            getClientUsers(activeClient.companyNumber, activeClient.clientId).then(users => {
                setEditClient({...activeClient.settings, users});
            });
        }, 1000);
    }, [activeClient]);

    const saveUpdatedClient = async () => {
        const updatedClient = await updateClient(editClient);
            setActiveClient(updatedClient);
            const clientIndex = clients.findIndex((client) => client.clientId === updatedClient.clientId);
            const updatedClients = [...clients];
            updatedClients[clientIndex] = updatedClient;
            setClients(updatedClients);
            createToast(TOAST_TYPES.SUCCESS, 'Client updated successfully');
    }


    const value = {
        clients, setClients,
        activeClient, setActiveClient,
        editClient, setEditClient,
        saveUpdatedClient,
        clientEditor, setClientEditor,
        newClientEditor, setNewClientEditor
    };
    
    return (
        <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
    )
};