import { createContext, useState, useEffect} from "react";
import { getKpis } from "../utils/kpi.utils";

export const KpisContext = createContext({
    kpis: Array, setKpis: () => null,
    healthAndSafetyKpis: Array, setHealthAndSafetyKpis: () => null,
    operationalKpis: Array, setOperationalKpis: () => null,
    customerServiceKpis: Array, setCustomerServiceKpis: () => null,
    managementKpis: Array, setManagementKpis: () => null,
    clientKpis: Object, setClientKpis: () => null
});

export const KpisProvider = ({children}) => {
    const [kpis, setKpis] = useState([]);
    const [healthAndSafetyKpis, setHealthAndSafetyKpis] = useState([]);
    const [operationalKpis, setOperationalKpis] = useState([]);
    const [customerServiceKpis, setCustomerServiceKpis] = useState([]);
    const [managementKpis, setManagementKpis] = useState([]);
    const [clientKpis, setClientKpis] = useState({});

    useEffect(() => {
        getKpis().then(kpis => {
            setKpis(kpis);
        })
    }, []);

    useEffect(() => {
        if(!kpis.length) return;
        setHealthAndSafetyKpis(kpis.filter(kpi => kpi.category === "h&s"));
        setOperationalKpis(kpis.filter(kpi => kpi.category === "operational"));
        setManagementKpis(kpis.filter(kpi => kpi.category === "management"));
        setCustomerServiceKpis(kpis.filter(kpi => kpi.category === "customer service"));
    }, [kpis]);

    const value = {
        kpis, setKpis,
        healthAndSafetyKpis, setHealthAndSafetyKpis,
        operationalKpis, setOperationalKpis,
        customerServiceKpis, setCustomerServiceKpis,
        managementKpis, setManagementKpis,
        clientKpis, setClientKpis
    };
    
    return (
        <KpisContext.Provider value={value}>{children}</KpisContext.Provider>
    )
};