import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ToastProvider } from './contexts/toast.context';
import { UserProvider } from './contexts/user.context';
import { ClientProvider } from './contexts/clients.context';
import { PortalProvider } from './contexts/portal.context';
import { PropertyProvider } from './contexts/property.context';
import { ContractsProvider } from './contexts/contracts.context';
import { EngineersProvider } from './contexts/engineers.context';
import { KpisProvider } from './contexts/kpis.context';
import { JobProvider } from './contexts/job.context';
import { SubcontractProvider } from './contexts/subcontract.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <ToastProvider>
                <UserProvider>
                    <ClientProvider>
                        <ContractsProvider>
                            <PropertyProvider>
                                <PortalProvider>
                                    <KpisProvider>
                                        <JobProvider>
                                            <EngineersProvider>
                                                <SubcontractProvider>
                                                    <Suspense fallback={<div>Loading...</div>}>
                                                        <App />
                                                    </Suspense>
                                                </SubcontractProvider>
                                            </EngineersProvider>
                                        </JobProvider>
                                    </KpisProvider>
                                </PortalProvider>
                            </PropertyProvider>
                        </ContractsProvider>
                    </ClientProvider>
                </UserProvider>
            </ToastProvider>
        </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
