import DoddnetApi from "../apis/doddnet.api";

export const getCompanyContracts = async (companyNumber) => {
    try {
        const {data} = await DoddnetApi.get(`/v2/vixen/contracts`, {
            params:{
                companyNumber
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getContractCategories = async () => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/contract-categories`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getUnallocatedEngineers = async (companyNumber) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/vixen/engineers`, {
            params: {
                company: companyNumber,
                unallocatedOnly: true
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getPriorities = async (companyNumber) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/vixen/priority-codes`, {
            params: {
                company: companyNumber
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}