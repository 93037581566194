import Keys from "../keys";
import axios from "axios";

const {API_URL} = Keys;

const DoddnetApi = axios.create({
    baseURL: API_URL,
    timeout: 90000,
    headers: {
        "Content-Type": "application/json",
        "api-key": process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV_KEY:process.env.REACT_APP_API_KEY,
        "api-key-user": process.env.NODE_ENV === 'development' ?process.env.REACT_APP_API_DEV_KEY_USER:process.env.REACT_APP_API_KEY_USER
    }
});

export default DoddnetApi;