import { createContext, useState, useEffect, useContext } from "react";
import { ClientContext } from "./clients.context";
import { getEngineers } from "../utils/engineer.utils";

export const EngineersContext = createContext({
    engineers: Array, setEngineers: () => null
});

export const EngineersProvider = ({children}) => {
    const { activeClient } = useContext(ClientContext);
    const [engineers, setEngineers] = useState([]);

    useEffect(() => {
        if(!activeClient) return setEngineers([]);
        getEngineers(activeClient.companyNumber).then((data) => {
            setEngineers(data);
        });
    }, [activeClient]);

    const value = {
        engineers, setEngineers
    };
    
    return (
        <EngineersContext.Provider value={value}>{children}</EngineersContext.Provider>
    )
};