import { createContext } from "react";
import toast from "react-hot-toast";

export const ToastContext = createContext({
    createToast: (type, message, options) => null,
    createToastPromise: (promise, {loading, success, error}) => null
});

export const TOAST_TYPES = {
    BLANK: toast,
    SUCCESS: toast.success,
    ERROR: toast.error,
    PROMISE: toast.promise
};

const defaultToastOptions = {
    duration: 5000,
    position: "top-center",
    iconTheme: {
        primary: "#000",
        secondary: "#fff"
    },
    ariaProps: {
        role: "status",
        "aria-live": "polite"
    }
}

export const ToastProvider = ({children}) => {
    const createToast = (type=TOAST_TYPES.BLANK, message = '', options = {}) => {
        if(type === TOAST_TYPES.PROMISE) return;
        type(message, {
            ...defaultToastOptions,
            ...options
        });
    };

    const createPromiseToast = (promise, {loading = 'Loading', success = 'Success', error = 'Error'}) => {
        toast.promise(promise, {
            loading,
            success,
            error
        })
    };

    const value = {
        createToast,
        createPromiseToast
    };

    return (
        <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
    );
}