import { createContext, useState, useEffect, useContext } from "react";
import { ClientContext } from "./clients.context";
import { getCompanyContracts, getContractCategories, getUnallocatedEngineers, getPriorities } from "../utils/contract.utils";

export const ContractsContext = createContext({
    vixenContracts: Array, setVixenContracts: () => null,
    categories: Array, setCategories: () => null,
    unallocatedEngineers: Array, setUnallocatedEngineers: () => null,
    priorities: Array, setPriorities: () => null
});

export const ContractsProvider = ({children}) => {
    const {activeClient} = useContext(ClientContext);
    const [vixenContracts, setVixenContracts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [unallocatedEngineers, setUnallocatedEngineers] = useState([]);
    const [priorities, setPriorities] = useState([]);

    useEffect(() => {
        if(!activeClient) return setVixenContracts([]);
        getCompanyContracts(activeClient.companyNumber).then((data) => {
            setVixenContracts(data[0].contracts);
        });
        getContractCategories().then((data) => {
            setCategories(data);
        });
        getUnallocatedEngineers(activeClient.companyNumber).then((data) => {
            setUnallocatedEngineers(data[0].engineers);
        });
        getPriorities(activeClient.companyNumber).then((data) => {
            setPriorities(data[0].priorityCodes);
        });
    }, [activeClient])

    const value = {
        vixenContracts, setVixenContracts,
        categories, setCategories,
        unallocatedEngineers, setUnallocatedEngineers,
        priorities, setPriorities
    };

    return (
        <ContractsContext.Provider value={value}>{children}</ContractsContext.Provider>
    );
}