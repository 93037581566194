import DoddnetApi from "../apis/doddnet.api";

export const getClients = async (clientList = []) => {
    try {
        const {data} = await DoddnetApi.get('/property-care/clients', {
            params: {
                clientList
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const updateClient = async (update) => {
    try {
        const {data} = await DoddnetApi.put('/property-care/client', update);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getMasterClients = async () => {
    try {
        const {data} = await DoddnetApi.get('/v1/vixen/clients');
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const createClient = async (client) => {
    try {
        const {data} = await DoddnetApi.post('/v1/property-care/client', client);
        return data;
    } catch (error) {
        console.error(error);
    }
}