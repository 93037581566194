import DoddnetApi from "../apis/doddnet.api";

export const getEngineers = async (companyNumber) => {
    if(!companyNumber) return [];
    try {
        const {data} = await DoddnetApi.get('/v1/vixen/engineers', {
            params: {
                company: companyNumber
            }
        });
        return data[0].engineers;
    } catch (error) {
        console.error(error);
        return [];
    }
}