/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from "react";
import { getUserInfoByToken } from "../utils/authentication.utils";
import { getUserSettings, getAllUsers } from "../utils/user.utils";
import io from "socket.io-client";
import Keys from "../keys";
import toast from "react-hot-toast";
import { Colors } from "../style-variables";

export const UserContext = createContext({
    currentUser: null, setCurrentUser: () => null,
    allUsers: Array, setAllUsers: () => null,
    forgotPasswordActive: Boolean, setForgotPasswordActive: () => null,
    profilePanel: Object, setProfilePanel: () => null,
    socket: Object, setSocket: () => null,
    knownErrorMessage: String, setKnownErrorMessage: () => null
});

export const UserProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [profilePanel, setProfilePanel] = useState({
        panelOpen: false,
        editingProfile: null
    });
    const [allUsers, setAllUsers] = useState([]);
    const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
    const [socket, setSocket] = useState(null);
    const [knownErrorMessage, setKnownErrorMessage] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(!token) return;
        getUserInfoByToken(token).then(user => {
            getUserSettings(user.user._id).then(settings => {
                setCurrentUser(settings);
            }).catch(error => {
                throw(error);
            })
        }).catch(error => {
            console.error(error);
        })
    }, []);

    useEffect(() => {
        if(!currentUser) return;
        const webSocket = io(Keys.API_URL+'/property-care');
        if(!socket) setSocket(webSocket);
        setProfilePanel({
            ...profilePanel,
            editingProfile: {...currentUser}
        })
        const lastUser = localStorage.getItem('lastUser');
        if(!lastUser) return;
        const updatedLastUser = {
            username: currentUser.username,
            avatar: currentUser.avatar
        }
        localStorage.setItem('lastUser', JSON.stringify(updatedLastUser));
        if(currentUser.propertyCareSettings.isSiteAdmin || currentUser.permissions.portalPropertyCare === 3){
            getAllUsers().then(users => {
                setAllUsers(users);
            })
        }
    }, [currentUser]);

    useEffect(() => {
        if(!socket) return;
        socket.on('errors', data => {
            if(!data) return;
            toast(data, {
                duration: Infinity,
                position: 'bottom-left',
                icon: '🛑',
                style: {
                    background: Colors.warning,
                    color: Colors.mainFont
                }
            })
            setKnownErrorMessage(data);
        });
        socket.emit('get-errors');
    }, [socket]);

    const value = {
        currentUser, setCurrentUser,
        allUsers, setAllUsers,
        forgotPasswordActive, setForgotPasswordActive,
        profilePanel, setProfilePanel,
        socket, setSocket,
        knownErrorMessage, setKnownErrorMessage
    };
    
    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    )
};