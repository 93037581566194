/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { ClientContext } from "./clients.context";
import { searchClientProperties, sortPropertiesByHouseNumber } from "../utils/properties.utils";
import { debounce } from "lodash";
import { getSiteDetails } from "../utils/site.utils";

export const PropertyContext = createContext({
    properties: Array, setProperties: () => null,
    propertiesLoading: Boolean, setPropertiesLoading: () => null,
    searchText: String, setSearchText: () => null,
    filteredProperties: Array, setFilteredProperties: () => null,
    largeResults: Boolean, setLargeResults: () => null,
    activeSite: Object, setActiveSite: () => null,
    loadingSite: Boolean, setLoadingSite: () => null,
    siteLoad: Object, setSiteLoad: () => null
});

export const PropertyProvider = ({children}) => {
    const {activeClient} = useContext(ClientContext);
    const [propertiesLoading, setPropertiesLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [largeResults, setLargeResults] = useState(false);
    const [loadingSite, setLoadingSite] = useState(false);
    const [siteLoad, setSiteLoad] = useState(null);
    const [activeSite, setActiveSite] = useState(null);

    const runFilter = useMemo(() => debounce(async (search_text, client) => {            
            if(search_text.length < 3) return setFilteredProperties([]);
            setPropertiesLoading(true);
            let filtered = await searchClientProperties(client, search_text);
            if(filtered.length > 500){ 
                filtered = filtered.splice(0, 500);
                setLargeResults(true);
            }
            filtered = filtered.sort(sortPropertiesByHouseNumber);
            setFilteredProperties(filtered);
            setPropertiesLoading(false);
        }, 500),
        []
    );

    useEffect(() => {
        setLargeResults(false);
        if(!searchText.length) return setFilteredProperties([]);
        runFilter(searchText, activeClient)
    }, [searchText]);

    useEffect(() => {
        if(!activeSite){
            localStorage.removeItem('site');
        }else{
            localStorage.setItem('site', activeSite.uprn);
        }
    }, []);

    useEffect(() => {
        if(!siteLoad){
            setActiveSite(null);
            return;
        }else{
            setLoadingSite(true);
            getSiteDetails(siteLoad.client, siteLoad.siteId, activeClient.companyNumber).then(siteDetails => {
                let address = siteDetails.siteAddress.split(",");
                address = {
                    address1: address[0],
                    address2: address[1],
                    address3: address[2],
                    address4: address[3],
                }
                let site = {
                    siteDetails: {...siteDetails, ...address},
                    activeJobs: siteDetails.jobsReactive.filter(job => job.reactiveStatus.toLowerCase() !== 'c'),
                    completeJobs: siteDetails.jobsReactive.filter(job => job.reactiveStatus.toLowerCase() === 'c')
                }
                setActiveSite(site);
                setLoadingSite(false);
            })
        }
    }, [siteLoad]);

    const value = {
        properties, setProperties,
        propertiesLoading, setPropertiesLoading,
        searchText, setSearchText,
        filteredProperties, setFilteredProperties,
        largeResults, setLargeResults,
        activeSite, setActiveSite,
        loadingSite, setLoadingSite,
        siteLoad, setSiteLoad
    };
    
    return (
        <PropertyContext.Provider value={value}>{children}</PropertyContext.Provider>
    )
};