import DoddnetApi from "../apis/doddnet.api";

export const getUserSettings = async (userId) => {
    if(!userId) return null;
    try {
        const {data} = await DoddnetApi.get('/property-care/user-settings', {
            params: {
                userId
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getAllUsers = async () => {
    try {
        const {data} = await DoddnetApi.get('/v1/user/users');
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getClientUsers = async (company, clientId) => {
    if(!clientId) return [];
    try {
        const {data} = await DoddnetApi.get('/property-care/client/users', {
            params: {
                clientId,
                company
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const updateUserAvatar = async (userId, avatar) => {
    try {
        const {data} = await DoddnetApi.post('/v1/user/avatar', {
            userId,
            avatar
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const updatePassword = async (userId, password) => {
    try {
        const {data} = await DoddnetApi.post('/v1/auth/password/set', {
            userId,
            password
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}