import DoddnetApi from "../apis/doddnet.api";

export const getClientProperties = async (client) => {
    if(!client) return [];
    try {
        const {data} = await DoddnetApi.get('/vixen/clients/sites', {
            params: {
                client
            }
        });
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const searchClientProperties = async (client, search = "") => {
    if(!client) return [];
    try {
        const {data} = await DoddnetApi.get('/vixen/clients/sites', {
            params: {
                client: client.clientId,
                address: search,
                ignoreSiteIdsBeginningWith: client.settings.ignoreSiteIdsBeginningWith
            }
        });
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const sortPropertiesByHouseNumber = (a, b) => {
    let matchA = a.address1.match(/\b\d+\b/);
    let matchB = b.address1.match(/\b\d+\b/);
    if(!matchA || !matchB) return -1;
    const houseNumberA = parseInt(a.address1.match(/\b\d+\b/)[0]);
    const houseNumberB = parseInt(b.address1.match(/\b\d+\b/)[0]);
    return houseNumberA - houseNumberB;
}