import { useContext, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { UserContext } from "./contexts/user.context";
import { SubcontractContext } from "./contexts/subcontract.context";

// import Toast from "./components/toast/toast.component";

// import Authentication from "./components/authentication/authentication.component";
// import RootRoute from "./routes/root/root.route";
// import HomeRoute from "./routes/home/home.route";
// import ClientRoute from "./routes/client/client.route";
// import ResetPasswordRoute from "./routes/reset-password/reset-password.route";
// import Error404Route from "./routes/404/404.route";
// import UserProfilePanel from "./components/user-profile-panel/user-profile-panel.component";
// import SubcontractorRoute from "./routes/subcontractor/subcontractor.route";
// import DismissibleToast from "./components/toast/dismissible-toast.component";
// import NewSubcontractEditor from "./components/new-subcontract-editor.jsx/new-subcontract-editor.component";

const Authentication = lazy(() => import ("./components/authentication/authentication.component"));
const RootRoute = lazy(() => import ("./routes/root/root.route"));
const HomeRoute = lazy(() => import ("./routes/home/home.route"));
const ClientRoute = lazy(() => import ("./routes/client/client.route"));
const ResetPasswordRoute = lazy(() => import ("./routes/reset-password/reset-password.route"));
const Error404Route = lazy(() => import ("./routes/404/404.route"));
const UserProfilePanel = lazy(() => import ("./components/user-profile-panel/user-profile-panel.component"));
const SubcontractorRoute = lazy(() => import ("./routes/subcontractor/subcontractor.route"));
const DismissibleToast = lazy(() => import ("./components/toast/dismissible-toast.component"));
const NewSubcontractEditor = lazy(() => import ("./components/new-subcontract-editor.jsx/new-subcontract-editor.component"));

const ResidentsRoute = lazy(() => import ("./routes/residents/residents.route"));

const App = () => {
    const { currentUser, profilePanel } = useContext(UserContext);
    const { newSubContractEditor } = useContext(SubcontractContext);

    return (
        <div className="App">
            {
                currentUser ? (
                    <Routes>
                        <Route path="/" element={<RootRoute />}>
                            <Route index element={<HomeRoute />} />
                            <Route path="/client/*" element={<ClientRoute />} />
                            {
                                (currentUser.accessRights || currentUser.propertyCareSettings.isSubContractorProfile || currentUser.permissions.portalSubcontractor === 3) ? (
                                    <Route path="/subcontract/*" element={<SubcontractorRoute />} />
                                ):null
                            }
                            {
                                currentUser.doddnetId === '1901' ? <Route path="/residents/*" element={<ResidentsRoute />} /> : null
                            }
                        </Route>
                    </Routes>
                ) : (
                    <Routes>
                        <Route path="/">
                            <Route index element={<Authentication />} />
                            <Route path="/reset/:token" element={<ResetPasswordRoute />} />
                            <Route path="/*" element={<Error404Route />} />
                        </Route>
                    </Routes>
                )
            }
            {/* <Toast /> */}
            <DismissibleToast />
            {profilePanel.panelOpen && <UserProfilePanel />}
            {
                newSubContractEditor.editorOpen && <NewSubcontractEditor />
            }
        </div>
    );
}

export default App;
