/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext } from "react";
import { PropertyContext } from "./property.context";
import { useLocation } from "react-router-dom";

export const PortalContext = createContext({
    toolbarBackButton: Boolean, setToolbarBackButton: () => null
});

export const PortalProvider = ({children}) => {
    const {setSearchText} = useContext(PropertyContext);
    const [toolbarBackButton, setToolbarBackButton] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === '/client'){
            setToolbarBackButton(false);
            setSearchText("");
        }else{
            setToolbarBackButton(true);
        }
    }, [location]);

    const value = {
        toolbarBackButton, setToolbarBackButton
    };
    
    return (
        <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
    )
};