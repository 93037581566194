import DoddnetApi from "../apis/doddnet.api";

export const getUserInfoByToken = async (token) => {
    DoddnetApi.defaults.headers.common['x-auth-token'] = token;
    try {
        const {data} = await DoddnetApi.post('/auth/authorize/user/info');
        return data;
    } catch (error) {
        throw(error);
    }
}

export const loginUserWithEmailAndPassword = async (username, password, rememberMe = false) => {
    if(!username || !password) return;
    const body = {
        username, password
    };
    try {
        const {
            data: {
                accessToken, error
            }
        } = await DoddnetApi.post('/auth/authorize/doddnet', body);
        sessionStorage.setItem('token', accessToken);
        const user = await getUserInfoByToken(accessToken);
        return {
            user: user.user, rememberMe, accessToken, error
        }
        
    } catch (error) {
        console.error(error);
        return error.response.data
    }
}

export const logoutUser = async () => {
    // localStorage.removeItem('lastUser');
    localStorage.removeItem('token');
    localStorage.removeItem('client');
    document.cookie = `token=; domain=doddnet.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `token=; domain=10.10.1.131; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const requestPasswordReset = async (email = '') => {
    if(!email && !email.length) return;
    try {
        const {data} = await DoddnetApi.post('/auth/forgot', {
            email,
            resetPath: `${window.location.protocol}//${window.location.host}/reset`
        });
        return data;
    } catch (error) {
        return error;
    }
}

export const resetPassword = async (token, password) => {
    if(!token) return;
    if(!password) return;
    try {
        const {data} = await DoddnetApi.post('/auth/reset', {
            token, password
        });
        return data;
    } catch (error) {
        return error;
    }
}