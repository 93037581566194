import DoddnetApi from "../apis/doddnet.api";

export const getSiteDetails = async (clientId, siteId, companyNumber, includeJobs = true) => {
    try {
        const {data} = await DoddnetApi.get('/v1/property-care/site', {
            params: {
                clientId,
                siteId,
                companyNumber,
                includeJobs
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getExistingContractJobDates = async (companyNumber, contract) => {
    if(!contract) return [];
    if(!companyNumber) return [];
    try {
        const {data} = await DoddnetApi.get('/v1/property-care/site/job/unavailableDates', {
            params: {
                companyNumber,
                contract
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}